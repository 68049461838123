import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | СпецЦентр Автообслуговування та Шиномонтажу
			</title>
			<meta name={"description"} content={"Відповіді на часті питання від нашої майстерні СпецЦентр Автообслуговування та Шиномонтажу"} />
			<meta property={"og:title"} content={"FAQ | СпецЦентр Автообслуговування та Шиномонтажу"} />
			<meta property={"og:description"} content={"Відповіді на часті питання від нашої майстерні СпецЦентр Автообслуговування та Шиномонтажу"} />
			<link rel={"shortcut icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileColor"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-greyD1" quarkly-title="FAQ">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				Відповіді на часті питання від нашої майстерні{"\n\n"}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Звідки я можу отримати додаткову інформацію про ваші послуги?{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="--light"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ви можете знайти більше інформації про наші послуги на нашому веб-сайті. Також, ви завжди можете звертатися до нашої команди за телефоном або електронною поштою, щоб отримати консультацію.{"\n\n"}
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Які види платежів ви приймаєте?{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="--light"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ми приймаємо наступні види платежів: готівка, кредитні та дебетові картки.{"\n\n"}
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи потрібно попередньо записуватися на обслуговування?{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="--light"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ми рекомендуємо попередньо записуватися, особливо під час сезонів перевзування гуми, щоб забезпечити вам найкращий сервіс та зекономити ваш час. Але ми також приймаємо клієнтів без попереднього запису.{"\n\n"}
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи надаєте ви послугу зберігання другого комплекта гуми чи набору дисків?{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="--light"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми надаємо послугу зберігання другого комплекта гуми чи набору дисків з гумою на нашому сучасному складі. Це допомагає зберегти ваші шини у відмінному стані та забезпечує зручність під час перевзування сезонів.{"\n\n"}
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Як часто рекомендується проводити шиномонтаж?{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="--light"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Зазвичай, рекомендується проводити шиномонтаж двічі на рік - на весну та восени, коли змінюються погодні умови. Однак це може варіюватися залежно від регіону та вашого стилю водіння. Наші фахівці радять звертатися до нас для консультації.{"\n\n"}
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Які гарантії надаєте на ваші ремонтні роботи?{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="--light"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ми надаємо гарантію на всі наші ремонтні роботи. Гарантійний термін може варіюватися залежно від виду робіт та запчастин, які використовуються. Більше інформації можна отримати у наших менеджерів.{"\n\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.FootedDesc />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523de57e2dd3a0022204b82"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});